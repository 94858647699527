<template>
  <div class="image">

    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#545c64"
      text-color="#fff" active-text-color="#ffd04b" router>
      <el-menu-item index="/page">页面设置</el-menu-item>
      <el-menu-item index="/video">视频管理</el-menu-item>
      <el-menu-item index="/image">图片管理</el-menu-item>
    </el-menu>

    <!-- 上传按钮 -->
    <div style="text-align: right; margin: 20px 0px;">
      <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :http-request="uploadImage"
        accept=".jpg, .png" :before-upload="beforeUpload" :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2mb</div>
      </el-upload>
    </div>


    <!-- 表单 -->
    <el-table :data="ossImageList" stripe style="width: 100%">
      <el-table-column prop="id" label="编号" width="80">
      </el-table-column>
      <el-table-column prop="name" label="内容">
      </el-table-column>

      <el-table-column label="操作" align="right">
        <template slot-scope="scope">
          <!-- 弹框 -->
          <el-button @click="openDelete(scope.row)" type="text" size="small" style="color: #F56C6C;">删除</el-button>

        </template>
      </el-table-column>


    </el-table>
  </div>
</template>

<script>
import tool from '../utils/oss.js'
import request from '@/utils/request'

export default {
  name: 'ImageManager',

  data() {
    return {
      activeIndex: '/image',
      ossImageList: [],
      fileList: [],
      ossUrl: '',
      ossName: ''

    }
  },

  methods: {
    openDelete(index) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {


        try {
          //1.删除阿里oss
          tool.oss.deleteObject(index.name)   //todo 判断成功与否
          //2.删除数据库
          request({
            url: `/imageDel`,//请求【插入视频】接口
            method: 'get',
            params: {
              name: index.name
            }

          }).then(result => {
            console.log(result);
            // this.$message.success('删除成功！')
            console.log('删除:', index.name)
          }).catch(error => {
            console.log(error);
            // this.$message.error('删除失败，请稍后再次尝试！')
          });


        } catch (error) {
          this.$message.error('删除失败，请稍后再次尝试！')
        }



        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // ***** 上传文件 *****
    uploadImage(option) {
      let ossUrl = ''
      let ossName = ''
      let result = ''

      try {
        result = tool.oss.uploadImage(option.file) //判断成功与否

        //如何获取Promise对象中的数据
        result.then((res) => {

          ossName = res.name
          let h = res.res.requestUrls[0]
          // 截取？之前的字符串
          const index = h.indexOf('?')
          if (index !== -1) {
            ossUrl = h.slice(0, index)
          }

          // console.log(ossName); //拿到了上传文件的名字--->为修改数据库奠定基础
          // console.log(ossUrl);  //拿到了上传文件的url--->为修改数据库奠定基础

          request({
            url: `/imageInsert`,//请求【插入视频】接口
            method: 'get',
            params: {
              name: ossName,
              url: ossUrl
            }

          }).then(result => {
            console.log(result);
            this.$message.success('上传成功！')

          }).catch(error => {
            console.log(error);
            this.$message.error('上传失败，请稍后再次尝试！')
          });

        })


      } catch (error) {
        this.$message.error('上传失败，请稍后再次尝试！')
      }



    },

    // 上传文件之前
    beforeUpload(file) {
      // 检查文件类型
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      // 检查文件大小
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJpgOrPng) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }

      return isJpgOrPng && isLt2M;
    }

  },



  // ***** 钩子 *****
  async created() {

    await request({
      url: "/image",//请求【视频数据】接口

    }).then(result => {
      this.ossImageList = result
      console.log('Image页面：')
      console.log(this.ossImageList)

    }).catch(error => {
      console.log(error);
    });


  },
}
</script>

<style>
/* 导航栏样式 */
#app > div > div > ul {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
#app > div > div {
  margin-top: 80px;
}

</style> 