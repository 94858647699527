import axios from 'axios'
import { getInfo } from '@/utils/storage'
//1.创建axios实例

const instance = axios.create({
    baseURL: 'https://api.lighting.sc.cn:8848/api/',
    // baseURL: 'http://localhost:8848/api/',
    timeout: 5000,
  });



//2.自定义配置 - 请求/响应 拦截器

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 携带token
    const getToken = getInfo()
    if (getToken) {
      config.headers.token = getToken
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  });




  //3.导出配置好的axios实例
  export default instance