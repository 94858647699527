import { client } from "../config/index.js";

const tool = {

  oss: {
    //上传视频
    async upload(file) {

        //文件名
        let fileName = 'video/' + file.name

        const result = await client.multipartUpload(fileName, file, {
          progress: function (p) {
            console.log('进度' + p)
          }
        });
        return result


    },
    //上传图片
    async uploadImage(file) {

      //文件名
      let fileName = 'images/' + file.name

      const result = await client.multipartUpload(fileName, file, {
        progress: function (p) {
          console.log('进度' + p)
        }
      });
      return result


  },
    //删除
    async deleteObject(file) {
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        const result = await client.delete(file);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    }
  }

};

export default tool;