import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'

//插件安装
Vue.use(Vuex)
//创建store对象
const store = new Vuex.Store({
    state:{
    },
    getters:{  
    },
    mutations:{
    },
    actions:{
    },
    modules:{ 
      user 
    }
})
export default store