//此处用于存放所有与登录相关的接口请求
import request from '@/utils/request'



//登录请求
export const codeLogin = (username, password) => {
  return request.post('/login', {

    username: username,
    password: password


  })
}