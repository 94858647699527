import { getInfo, setInfo } from '@/utils/storage'

export default {
  namespaced: true,
  state () {
    return {
      token: getInfo()
    }
  },
  mutations: {
    setToken (state, str) {
      state.token = str
      setInfo(str)
    }
  },
  actions: {},
  getters: {}

}
