<template>
  <div class="login">
    <!-- 头部 -->
    <div class="login-header">
      <img src="@/assets/logo-black.png" alt="" />
    </div>
    <!-- 主体 -->
    <el-form status-icon ref="user" class="demo-user">
      <el-form-item prop="username">
        <el-input type="text" v-model="username" autocomplete="off" placeholder="用户名" class="input" style="width:100%;"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="password" autocomplete="off" placeholder="密码"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" style="margin-top: 50px;" @click="login(username, password)">登录</el-button>
      </el-form-item>
    </el-form>
    
    <!-- <el-button type="primary" @click="testFn()">测试登录</el-button> -->
  </div>
</template>

<script>
import { codeLogin } from "@/api/login";

// import request from '@/utils/request'

export default {
  name: "LoginIndex",
  created() {
    // console.log(this.$store);
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    //登录
    async login(username, password) {
      //校验
      if (username.length < 4) {
        this.$message.error("用户名必须大于等于4位", false);
        console.log("用户名必须大于等于4位");
        return;
      }
      if (password.length < 6) {
        this.$message.error("密码必须大于等于6位", false);
        console.log("密码必须大于等于6位");
        return;
      }

      //请求登录

      const result = await codeLogin(username, password)
      this.$store.commit("user/setToken", result.data)//存入vuex
      console.log(result)

      if (result.code === 1) {

        this.$message.success("登录成功！")
        //跳转
        this.$router.push("/page");
      } else {
        this.$message.error("登录失败，请检查用户名和密码")

      }







    },

    async testFn() {
      try {
        const response = await this.$axios.post(
          "http://localhost:8848/api/login",
          {
            username: "sunyan",
            password: "123456",
          }
        );
        console.log(response);
      } catch (error) {
        console.log(error);
        this.$message.error("登录失败，请检查用户名和密码");
      }
    },
  },
};
</script>

<style>
.login-header img {
  margin: 0 auto;
  

  @media (max-width: 768px) {
    margin-top: 50px;
    width: 220px;
  }

  @media (min-width: 768px) {
    margin-top: 200px;
    width: 240px;
  }

  @media (min-width: 992px) {
    margin-top: 200px;
    width: 280px;
  }

  @media (min-width: 1200px) {
    margin-top: 200px;
    width: 300px;
  }

  @media (min-width: 1900px) {
    margin-top: 200px;
    width: 400px;
  }
}

.demo-user {
  margin: 0 auto;
  padding-top: 100px;



  @media (max-width: 768px) {
    width: 350px;
  }
  

  @media (min-width: 768px) {
    width: 350px;
  }

  @media (min-width: 992px) {
    width: 400px;
  }

  @media (min-width: 1200px) {
    width: 500px;
  }

  @media (min-width: 1900px) {
    width: 600px;
  }

}
</style>