import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import store from '@/store/index'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import "./assets/style/global.css"



Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$axios = axios;  //写成原型属性，    

new Vue({
  render: h => h(App),
  router: router,
  store                   //注入路由
}).$mount('#app')
