//1.下载路由npm install vue-router@3.6.5

import Page from '@/views/Page.vue'
import Video from '@/views/Video.vue'
import Image from '@/views/Image.vue'
import NotFound from '@/views/NotFound.vue'
import Login from '@/views/Login.vue'

                               
 
import Vue from 'vue'
import VueRouter from 'vue-router'  //2.引入路由



Vue.use(VueRouter)                  //3.安装路由

const router = new VueRouter({      //4.创建路由
    routes: [
    {path: '/', redirect: '/login'},
    {path: '/login', component: Login},
    {path: '/page', component: Page},
    {path: '/video', component: Video},
    {path: '/image', component: Image},
    {path: '*', component: NotFound}

    ]
})

export default router               //5.导出路由