//约定一个通用的键名
const INFO_KEY = 'lighting_info'

// 获取信息 
export const getInfo = () => {
  const defaultInfo = ''
  const reslut = localStorage.getItem(INFO_KEY)
  return reslut ? JSON.parse(reslut) : defaultInfo
}
// 设置信息
export const setInfo = (str) => {
  localStorage.setItem(INFO_KEY, JSON.stringify(str))
}
// 删除信息
export const removeInfo = () => {
  localStorage.removeItem(INFO_KEY)
}