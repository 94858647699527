<template>
  <div id="app">

    <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#545c64"
      text-color="#fff" active-text-color="#ffd04b" router>
      <el-menu-item index="/page">页面设置</el-menu-item>
      <el-menu-item index="/video">视频管理</el-menu-item>
      <el-menu-item index="/image">图片管理</el-menu-item>
    </el-menu> -->
    <div class="page-content">
      <router-view></router-view>
    </div>




  </div>

</template>

<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<script>


export default {
  name: 'App',
  components: {
    
  },
  data() {
    return {
      activeIndex: '/page',

    };
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
  
}

</style>
