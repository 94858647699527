<template>
  <div class="page">

    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#545c64"
      text-color="#fff" active-text-color="#ffd04b" :router='true'>
      <el-menu-item index="/page">页面设置</el-menu-item>
      <el-menu-item index="/video">视频管理</el-menu-item>
      <el-menu-item index="/image">图片管理</el-menu-item>
    </el-menu>



    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item v-for="item in filteredPageList" :key="item.id" :label="item.tag">
        <el-input v-model="item.value"></el-input>
      </el-form-item>

      <div class="details" >当前主视频：<span class="sub-details">{{ selectedMainVideoName.substring(6) }}</span></div>
      <!-- 主视频 -->
      <el-form-item :label="mainVideoLabel" class="selectLable">
        <el-select v-model="selectMainVideoUrl" placeholder="请选择-主视频" style="width: 100%;">
          <el-option v-for="item in ossVideoList" :key="item.id" :label="item.name" :value="item.url"></el-option>
        </el-select>
      </el-form-item>


      <div class="details" >当前子视频1：<span class="sub-details">{{ selectedSubVideoName1.substring(6) }}</span></div>
      <!-- 子视频1 -->
      <el-form-item :label="subVideoLabel1" class="selectLable">
        <el-select v-model="selectSubVideoUrl1" placeholder="请选择-子视频1" style="width: 100%;">
          <el-option v-for="item in ossVideoList" :key="item.id" :label="item.name" :value="item.url"></el-option>
        </el-select>
      </el-form-item>


      <div class="details" >当前子视频2：<span class="sub-details">{{ selectedSubVideoName2.substring(6) }}</span></div>
      <!-- 子视频2 -->
      <el-form-item :label="subVideoLabel2" class="selectLable">
        <el-select v-model="selectSubVideoUrl2" placeholder="请选择-子视频2" style="width: 100%;">
          <el-option v-for="item in ossVideoList" :key="item.id" :label="item.name" :value="item.url"></el-option>
        </el-select>
      </el-form-item>


      <div class="details" >当前子视频3：<span class="sub-details">{{ selectedSubVideoName3.substring(6) }}</span></div>
      <!-- 子视频3 -->
      <el-form-item :label="subVideoLabel3" class="selectLable">
        <el-select v-model="selectSubVideoUrl3" placeholder="请选择-子视频3" style="width: 100%;">
          <el-option v-for="item in ossVideoList" :key="item.id" :label="item.name" :value="item.url"></el-option>
        </el-select>
      </el-form-item>


      <div class="details" >当前子视频4：<span class="sub-details">{{ selectedSubVideoName4.substring(6) }}</span></div>
      <!-- 子视频4 -->
      <el-form-item :label="subVideoLabel4" class="selectLable">
        <el-select v-model="selectSubVideoUrl4" placeholder="请选择-子视频4" style="width: 100%;">
          <el-option v-for="item in ossVideoList" :key="item.id" :label="item.name" :value="item.url"></el-option>
        </el-select>
      </el-form-item>

      <div class="details" >当前主视频封面：<span class="sub-details">{{ selectedMainVideoCoverName }}</span></div>
      <!-- 主视频封面 -->
      <el-form-item :label="mainVideoCover" class="selectLable">
        <el-select v-model="selectMainVideoCoverUrl" placeholder="请选择-主视频封面" style="width: 100%;">
          <el-option v-for="item in ossImageList" :key="item.id" :label="item.name" :value="item.url"></el-option>
        </el-select>
      </el-form-item>


      <el-form-item class="btn">
        <el-button type="primary" @click="onSubmit" class="lippb-btn">提交</el-button>
        <!-- <el-button>取消</el-button> -->
      </el-form-item>
      
    </el-form>

  </div>
</template>


<script>
import request from '@/utils/request'

export default {
  name: 'PageSetting',

  data() {
    return {
      activeIndex: '/page',
      ossVideoList: [],
      ossImageList: [],
      pageList: [],

      mainVideoLabel: '主视频',
      selectMainVideoUrl: '',    // 存储选中的视频 URL
      selectedMainVideoName: '', // 存储选中的视频名称

      subVideoLabel1: '子视频1',
      selectSubVideoUrl1: '',    // 存储选中的视频 URL
      selectedSubVideoName1: '', // 存储选中的视频名称

      subVideoLabel2: '子视频2',
      selectSubVideoUrl2: '',    // 存储选中的视频 URL
      selectedSubVideoName2: '', // 存储选中的视频名称

      subVideoLabel3: '子视频3',
      selectSubVideoUrl3: '',    // 存储选中的视频 URL
      selectedSubVideoName3: '', // 存储选中的视频名称

      subVideoLabel4: '子视频4',
      selectSubVideoUrl4: '',    // 存储选中的视频 URL
      selectedSubVideoName4: '', // 存储选中的视频名称

      mainVideoCover: '主视频封面',
      selectMainVideoCoverUrl: '',     // 存储选中的主视频封面 URL
      selectedMainVideoCoverName: '',    // 存储选中的主视频封面名称

      form: {

      },


    }
  },

  watch: {
    selectMainVideoUrl(newVal) {
      const selectedVideoItem = this.ossVideoList.find(item => item.url === newVal)
      if (selectedVideoItem) {
        this.selectedMainVideoName = selectedVideoItem.name
        // console.log('选中的视频名称:', this.selectedMainVideoName)
        // this.mainVideoLabel = '主视频：' + this.selectedMainVideoName

        this.pageList[9].value = this.selectMainVideoUrl //主视频->URL
      }
    },
    selectSubVideoUrl1(newVal) {
      const selectedVideoItem = this.ossVideoList.find(item => item.url === newVal)
      if (selectedVideoItem) {
        this.selectedSubVideoName1 = selectedVideoItem.name
        // console.log('选中的视频名称:', this.selectedSubVideoName1)
        // this.subVideoLabel1 = '子视频1：' + this.selectedSubVideoName1

        this.pageList[21].value = this.selectSubVideoUrl1 //子视频1->URL
      }
    },
    selectSubVideoUrl2(newVal) {
      const selectedVideoItem = this.ossVideoList.find(item => item.url === newVal)
      if (selectedVideoItem) {
        this.selectedSubVideoName2 = selectedVideoItem.name
        // console.log('选中的视频名称:', this.selectedSubVideoName2)
        // this.subVideoLabel2 = '子视频2：' + this.selectedSubVideoName2

        this.pageList[22].value = this.selectSubVideoUrl2 //子视频2->URL
      }
    },
    selectSubVideoUrl3(newVal) {
      const selectedVideoItem = this.ossVideoList.find(item => item.url === newVal)
      if (selectedVideoItem) {
        this.selectedSubVideoName3 = selectedVideoItem.name
        // console.log('选中的视频名称:', this.selectedSubVideoName3)
        // this.subVideoLabel3 = '子视频3：' + this.selectedSubVideoName3

        this.pageList[22].value = this.selectSubVideoUrl3 //子视频3->URL
      }
    },
    selectSubVideoUrl4(newVal) {
      const selectedVideoItem = this.ossVideoList.find(item => item.url === newVal)
      if (selectedVideoItem) {
        this.selectedSubVideoName4 = selectedVideoItem.name
        // console.log('选中的视频名称:', this.selectedSubVideoName4)
        // this.subVideoLabel4 = '子视频4：' + this.selectedSubVideoName4

        this.pageList[24].value = this.selectSubVideoUrl4 //子视频4->URL
      }
    },
    selectMainVideoCoverUrl(newVal) {
      const selectedMainVideoCoverItem = this.ossImageList.find(item => item.url === newVal)
      if (selectedMainVideoCoverItem) {
        this.selectedMainVideoCoverName = selectedMainVideoCoverItem.name
        // console.log('选中的视频封面名称:', this.selectedMainVideoCoverName)
        this.pageList[10].value = this.selectMainVideoCoverUrl //主视频封面->URL
      }
    },
  },

  computed: {
    filteredPageList() {
      return this.pageList.filter(item => item.type === 'text');
    },
  },

// ***** 钩子 *****
  async created() {
    console.log('发送初始化渲染请求');
    // console.log(this.$store);
    

    await request({
      url: "/selectPage",//请求【页面数据】接口

    }).then(res => {
      this.pageList = res
      // console.log(this.pageList)

    }).catch(error => {
      console.log(error);
    });




    await request({
      url: "/video", //请求【视频数据】接口

    }).then(result => {
      this.ossVideoList = result
      // console.log(this.ossVideoList)

      //原始主视频名字
      const firstMainVideoName = this.ossVideoList.find(item => item.url === this.pageList[9].value)
      if (firstMainVideoName) {
        this.selectedMainVideoName = firstMainVideoName.name
        // console.log('原始的主视频名称:', this.selectedMainVideoName)

      }
      //原始子视频-1名字
      const firstSubVideoName1 = this.ossVideoList.find(item => item.url === this.pageList[21].value)
      if (firstSubVideoName1) {
        this.selectedSubVideoName1 = firstSubVideoName1.name
        // console.log('原始的子视频1名称:', this.selectedSubVideoName1)

      }
      //原始子视频-2名字
      const firstSubVideoName2 = this.ossVideoList.find(item => item.url === this.pageList[22].value)
      if (firstSubVideoName2) {
        this.selectedSubVideoName2 = firstSubVideoName2.name
        // console.log('原始的子视频2名称:', this.selectedSubVideoName2)

      }
      //原始子视频-3名字
      const firstSubVideoName3 = this.ossVideoList.find(item => item.url === this.pageList[23].value)
      if (firstSubVideoName3) {
        this.selectedSubVideoName3 = firstSubVideoName3.name
        // console.log('原始的子视频3名称:', this.selectedSubVideoName3)

      }
      //原始子视频-4名字
      const firstSubVideoName4 = this.ossVideoList.find(item => item.url === this.pageList[24].value)
      if (firstSubVideoName4) {
        this.selectedSubVideoName4 = firstSubVideoName4.name
        // console.log('原始的子视频4名称:', this.selectedSubVideoName4)

      }




    }).catch(error => {
      console.log(error);
    });


    await request({
      url: "/image",//请求【视频数据】接口

    }).then(result => {
      this.ossImageList = result
      // console.log('Image页面：')
      // console.log(this.ossImageList)

      //原始主视频封面名字
      const firstMainVideoCoverName = this.ossImageList.find(item => item.url === this.pageList[10].value)
      if (firstMainVideoCoverName) {
        this.selectedMainVideoCoverName = firstMainVideoCoverName.name
        // console.log('原始的主视频封面名称:', this.selectedMainVideoCoverName)

      }

    }).catch(error => {
      console.log(error);
    });






  },
  methods: {
    onSubmit() {


      request({
        url: "/updatePage",//请求【测试】接口
        method: 'post',
        data:{
              sliderImgUrl1:    this.pageList[0].value,
              sliderTitleUp1:   this.pageList[1].value,
              sliderTitleDown1: this.pageList[2].value,
              sliderImgUrl2:    this.pageList[3].value,
              sliderTitleUp2:   this.pageList[4].value,
              sliderTitleDown2: this.pageList[5].value,
              sliderImgUrl3:    this.pageList[6].value,
              sliderTitleUp3:   this.pageList[7].value,
              sliderTitleDown3: this.pageList[8].value,
              mainVideo:        this.pageList[9].value,
              mainVideoImg:     this.pageList[10].value,
              midTitleUp:       this.pageList[11].value,
              midTitleDown:     this.pageList[12].value,
              blockTitle1:      this.pageList[13].value,
              blockTitle2:      this.pageList[14].value,
              blockTitle3:      this.pageList[15].value,
              blockTitle4:      this.pageList[16].value,
              blockImg1:        this.pageList[17].value,
              blockImg2:        this.pageList[18].value,
              blockImg3:        this.pageList[19].value,
              blockImg4:        this.pageList[20].value,
              blockVideo1:      this.pageList[21].value,
              blockVideo2:      this.pageList[22].value,
              blockVideo3:      this.pageList[23].value,
              blockVideo4:      this.pageList[24].value,

        }
      }).then(result => {
        console.log(result)
        this.$message.success('恭喜你，提交成功！')
      }).catch(error => {
        console.log(error)
        this.$message.error('网络不畅，请稍后再次尝试！')
      });


    },
    
  }

}
</script>

<style>
.page {
  margin-top: 20px;
}
.details {
  /* font-size: 16px; */
  color: #fff;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  background-color: rgb(84, 92, 100);
}
.selectLable {
  margin-top: 15px;
}
.sub-details {
  font-size: 12px;
}

/* 导航栏样式 */
#app > div > div > ul {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

#app > div > div > form {
  margin-top: 60px;
}


</style>