import OSS from "ali-oss";

//实例化 OSS：创建OSS对象
export const client = new OSS({
  bucket: 'web-lippb',
  region: 'oss-cn-chengdu',
  accessKeyId: 'LTAI5tHKV4mi7we6DFYNuekd',
  accessKeySecret: 'qKIbtMpgBwpeVw92J4dzOUK8DNb54D',
  endpoint: 'oss-cn-chengdu.aliyuncs.com',
  secure: true,
});